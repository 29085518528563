import Link from "next/link";
import React from "react";
import product from "../data/products";
import Image from "next/image";

function GallerySection() {
  const Cake = ({
    slug,
    src,
    title,
    shortDescription,
    price,
  }: {
    slug: string;
    src: string;
    title: string;
    shortDescription: string;
    price: number;
  }) => {
    const formattedPrice = (price / 100).toFixed(2);
    return (
      <Link href={slug}>
        <div className="w-auto h-auto p-2 border-white bg-white overflow-hidden rounded-lg shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <Image
            className="object-contain"
            src={src}
            alt={shortDescription}
            layout="responsive"
            width={259}
            height={420}
          />
          <div className="px-2 bg-white text-primary flex flex-col overflow-hidden">
            <h2 className="font-bold text-[#454545] text-lg truncate m-0 mt-1">{title}</h2>
            <p className="font-medium text-center text-[#454545] ">${formattedPrice}</p>
            <button className="btn btn-primary  bg-[#454545] text-sm font-bold rounded-full text-[#F6F4D2] uppercase mt-2">
              Add To Cart
            </button>
          </div>
        </div>
      </Link>
    );
  };

  // Filter products to only show the smallest size
  const uniqueProducts = product.reduce<Record<string, typeof product[0]>>((acc, curr) => {
    const baseSku = curr.id.split("_")[1];
    if (!acc[baseSku] || acc[baseSku].price > curr.price) {
      acc[baseSku] = curr;
    }
    return acc;
  }, {});

  return (
    <>
      <h1 className="text-[#454545] font-bold mt-12 text-center text-4xl sm:text-7xl">
        Order Now!
      </h1>
      <div className="text-center my-6 max-w-4xl mx-auto">
        <p className="text-[#454545] text-lg font-light px-6">
          We believe in using the freshest ingredients to ensure the highest
          quality and taste. Every cake is baked to perfection just before
          delivery, preserving its freshness and aroma, so you can enjoy a
          delightful treat that tastes as good as it looks.
        </p>
      </div>
      <div
        className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 max-w-6xl sm:mx-auto gap-2 mx-4  py-6 pb-12"
        id="gallery"
      >
        {Object.values(uniqueProducts).map((x) => (
          <div className="space-y-2" key={x.id}>
            <Cake
              slug={`/products/${x.id}`}
              src={x.image}
              title={x.name}
              shortDescription={x.description}
              price={x.price}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default GallerySection;
