import { NextPage } from "next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Layout from "../components/Layout";
import LandingPageLayout from "../components/LandingPageLayout";
import LandingSection from "../components/LandingSection";
import GallerySection from "../components/GallerySection";
import SignupSection from "../components/SignupSection";
import BlogSection from "../components/BlogSection";
import RightAlignedBorderBottomFAQ from "../sections/RightAlignedBorderBottomFAQ";
import OurLocation from "../sections/OurLocation";
import AboutSection from "../sections/AboutSection"

const IndexPage: NextPage = () => {
  return (
    <LandingPageLayout>
      <div id="landing-section">
        <LandingSection />
      </div>
      <div className="w-full h-[1px] sm:h-[5px] max-w-6xl mx-auto px-4">
        <div className="w-full h-full bg-[#454545]"></div>
      </div>
      <div id="gallery-section">
        <GallerySection />
      </div>
      <div className="w-full h-[1px] sm:h-[5px] max-w-6xl mx-auto px-4">
        <div className="w-full h-full bg-[#454545]"></div>
      </div>
      <div id="faq-section">
        <RightAlignedBorderBottomFAQ />
      </div>
      <div id="about-section">
        <AboutSection />
      </div>
    </LandingPageLayout>
  );
};

export default IndexPage;
