import Link from "next/link";
import React, { useState } from "react";
import { useRouter } from 'next/router';
import { NavigationFilled } from "@fluentui/react-icons";
import Image from "next/image";
import logo from "../assets/logo.png";
import bg from "../assets/bg.png"
import bgPotsOverlay from "../assets/bg-pots-overlay.png"
import main from "../assets/main.png"
import orange from "../assets/orange.png"
import delicacies from "../assets/delicacies.png"
import biscuit from "../assets/biscuit.png"
import Navigation from "./Navigation";

function LandingSection() {
  return (
    <div className="bg-[#fff]">
      <Navigation />
      <div className="h-[40rem] sm:h-[60rem] w-screen flex sm:justify-center sm:items-center relative">
  
        <div className="max-w-6xl mx-auto absolute z-20 pt-20 h-[40rem] sm:h-[60rem] sm:pt-56">
          <div className="w-full p-4 sm:p-0">
            <Image
              src={main}
              alt="Main Image"
              layout="responsive"
              width={100}
              height={50}
              className="shadow-xl"
            />
          </div>
          <div className="flex flex-col-reverse md:flex-row sm:mt-4">
            <div className="md:w-1/2 p-4 flex flex-col pt-6 sm:pt-4">
              <p className="text-[#454545] text-center sm:text-left mb-4 pt-4 text-thin text-lg">We are located in Western Australia, in Perth. Our Gourmet Delicacies proudly crafted lovingly handmade cakes with our fresh and unique ingredients that make us different from others.</p>
              <Link href="#gallery-section" className="btn btn-primary pt-4 shadow-[0_4px_4px_rgba(69,69,69,0.2)] text-[#69443C] font-bold rounded-full uppercase mx-auto sm:mx-0 sm:w-40">Buy Now!</Link>
            </div>
            <div className="md:w-1/2 grid grid-cols-3 sm:gap-4 pl-4 sm:pt-4">
              <Image
                src={orange}
                alt="Image 1"
                layout="cover"
                width={100}
                height={100}
                className="block sm:hidden shadow-xl"
              />
              <Image
                src={delicacies}
                alt="Image 2"
                layout="cover"
                width={100}
                height={100}
                className="block sm:hidden shadow-xl"
              />
              <Image
                src={biscuit}
                alt="Image 3"
                layout="cover"
                width={100}
                height={100}
                className="block sm:hidden shadow-xl"
              />
              <Image
                src={orange}
                alt="Image 1"
                layout="cover"
                width={200}
                height={100}
                className="hidden sm:block"
              />
              <Image
                src={delicacies}
                alt="Image 2"
                layout="cover"
                width={200}
                height={100}
                className="hidden sm:block"
              />
              <Image
                src={biscuit}
                alt="Image 3"
                layout="cover"
                width={200}
                height={100}
                className="hidden sm:block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingSection;
