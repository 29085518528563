import React from "react";
import main1 from "../assets/main-1.png";
import main3 from "../assets/main-3.png";
import Image from "next/image";

const FAQItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => (
  <div className="mb-10">
    <h3 className="flex items-center text-lg font-bold text-[#454545]">
      {question}
    </h3>
    <p className="text-[#454545]">{answer}</p>
  </div>
);

function RightAlignedBorderBottomFAQ() {
  const faqs = [
    {
      question: "How do I order?",
      answer:
        "You can place an order via our website for both weekends and weekdays. Please ensure that your order is placed at least 24 hours in advance. If you have any questions or need further clarification, feel free to message us.",
    },
    {
      question: "When can I order?",
      answer:
        "Orders must be placed at least 24 hours before the desired delivery or pickup time.",
    },
    {
      question: "When will I receive my order?",
      answer:
        "You will receive your order at the time you planned, provided we have availability. Orders are typically fulfilled within the next 24 hours if all conditions are met.",
    },
    {
      question: "Do you have a minimum order value?",
      answer:
        "No, there is no minimum order value. You can place as many orders as you like.",
    },
    {
      question: "Where can I pick up my order?",
      answer: "On weekdays, you can pick up your order at our location.",
    },
    {
      question: "Do you have delivery services?",
      answer:
        "Yes, we offer delivery services on Saturday mornings between 8 AM and 12 PM. A delivery fee will be applied (please contact us for the exact fee). However, we offer free delivery for orders over $100.",
    },
    {
      question: "Can I order a meal for someone else?",
      answer:
        "Yes, we have a gift order option available. If you wish to order for someone else, you can leave a note with your gift in the notes section of your order, and we will include it with the delivery.",
    },
    {
      question: "How can I contact your kitchen?",
      answer:
        "You can contact us via email at [your email], send a direct message on Instagram, message us on Facebook, or reach out through our website",
    },
  ];

  return (
    <>
      {/* FAQ */}
      <section className="border-t-2 border-white">
        <div
          className=" mx-4  max-w-6xl lg:mx-auto pt-12"
        >
          <div
            className="flex flex-col text-left gap-4"
          >
            <div className="flex flex-col md:flex-row gap-6">
              <div className="md:w-1/2">
                <Image
                  height={200}
                  width={200}
                  src={main1}
                  alt="FAQ Image 1"
                  className="w-full h-full object-cover shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-lg"
                />
              </div>
              <div className="md:w-1/2">
                <h2 className="mb-8 text-4xl tracking-tight font-bold text-[#454545]">
                  Frequently Asked Questions
                </h2>
                {faqs.slice(0, 4).map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
            </div>
            <div
              className="flex flex-col-reverse md:flex-row md:gap-6"
            >
              <div className="md:w-1/2">
                {faqs.slice(4, 8).map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
              <div className="md:w-1/2 pb-8 md:pb-0">
                <Image
                  height={200}
                  width={200}
                  src={main3}
                  alt="FAQ Image 2"
                  className="w-full h-full object-cover shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ */}
    </>
  );
}

export default RightAlignedBorderBottomFAQ;
